.radio-group{
    position: relative;
}

.radio{
    display:inline-flex;
    align-items: center;
    width:30px;
    height: 30px;
    border-radius: 100%;
    background-color:rgb(241,243,244);
    border: 2px solid rgb(241,243,244);
    cursor:pointer;
    margin: 2px 0; 
    margin-right: 8px;
    -webkit-box-align: center;
    justify-content: center;
}

.radio1{
    display:inline-flex;
    align-items: center;
    width:30px;
    height: 30px;
    border-radius: 100%;
    background-color:rgb(26,115,232); ;
    border: 2px solid rgb(26,115,232);
    cursor:pointer;
    margin: 2px 0; 
    margin-right: 8px;
    -webkit-box-align: center;
    justify-content: center;
}

.radio.selected{
    border-color: grey;
}

.spaceAtTopForDate{
    margin-top: 60px;
}

.spaceAtTopForOccurence{
    margin-top: -70px;
    margin-left: -38px;
}