.common-form {
}
.inactive-button {
  //on submitting the button is disabled,this is the css
  opacity: 0.5;
  pointer-events: none;
}
.title-on-border-box.bg-blue-l {
  //chilling Unit profile color in site form comes from here
  background:#F6EFFF
}
.device-wrapper {
}

div.MuiChip-root.gateway-label.MuiChip-deletable{
  display: inline-table !important;
  width: 950px !important;
  overflow-wrap: anywhere !important;
}
  
span.MuiChip-label{
  white-space: break-spaces;
}
