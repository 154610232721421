body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  background-color: #f6f9fc !important;
  overflow: hidden; }

button:focus {
  outline: none; }

img:focus {
  outline: none; }

p {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px !important; }

span {
  font-size: 12px; }

/*.MuiIconButton-root:hover {
  background-color: rgba(63, 81, 181, 0.08) !important;
}*/
.blue-num {
  margin-left: 5px;
  font-size: 14px;
  color: #307ec2;
  font-weight: 600; }

.fa {
  color: #5799d1;
  font-size: 16px;
  text-align: center; }

.fa-globe {
  color: rgba(243, 241, 252, 0.55); }

a {
  color: #5799d1;
  cursor: pointer; }

h2 {
  font-size: 16px;
  font-weight: 501; }

h3 {
  font-weight: 600;
  font-size: 12px;
  margin: 1em 0em; }

tbody tr td {
  text-align: center; }

tbody tr td:first-child span {
  cursor: pointer; }
  tbody tr td:first-child span:hover {
    color: black; }

sup {
  top: -0.1em;
  font-size: 14px; }

.track-vertical {
  background-color: #c4cfdd;
  height: 100%;
  top: 0;
  right: 0; }

.track-vertical-index {
  margin-top: 5em;
  height: calc(100vh - 5em); }

.thumb-vertical {
  background-color: #478fcc;
  border-radius: 2em; }

/*.MuiTypography-body1 {
  font-size: 12px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // "Critical Alerts in WebViewChart.js is targetted from here
}*/
.card-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center; }

/* Site Wrapper */
/*.MuiOutlinedInput-root,
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover,
input {
  font-size: 12px !important;
  //all input fields are targetted from here
}*/
.site-add {
  border: 1px solid #fff;
  padding: 15px 0 15px 15px;
  margin-bottom: 20px;
  border-radius: 3px; }

.site-profile {
  border: 2px solid #fff;
  margin: 5px !important;
  border-radius: 3px; }

.device-profile {
  border: 2px solid #fff;
  border-radius: 3px;
  margin-left: 2px !important;
  margin-bottom: 15px !important;
  margin-top: 2px !important;
  margin-right: -2px !important; }

.common-device {
  border: 2px solid #fff;
  border-radius: 3px;
  margin: 1px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.logout-btn {
  position: absolute;
  right: 0;
  padding: 10px;
  cursor: pointer; }

.data-table tr th, .data-table tr td {
  text-overflow: initial !important; }

.mandatoryField::after {
  content: "*";
  color: red;
  font-weight: bold; }

.MuiFormLabel-asterisk {
  color: red; }

@media screen and (max-width: 600px) {
  /* Login Page */
  .auth-page > .container {
    margin-top: 1rem; }
  .container {
    padding-right: 1rem;
    padding-left: 1rem; }
  /* Table */
  .page-btm-data,
  .page-btn {
    text-align: center !important; } }

/* Mobile Resolution Landscape */
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .auth-page {
    height: 160vh !important; } }

.data-table {
  background: #fff;
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif; }

.data-table tr th {
  text-align: center;
  font-size: 12px;
  font-weight: 600; }

.data-table tr td {
  font-size: 13px;
  padding: 0.5rem; }

.data-table tr th,
.data-table tr td {
  overflow-x: auto;
  text-overflow: ellipsis;
  /* padding: 1rem 2rem; */ }

.data-table .fa-line-chart {
  padding: 0.5rem;
  color: #296aab;
  font-size: 16px; }

.data-table tbody {
  background: #fff; }

.data-table table {
  border-spacing: 0;
  border: 1px solid black; }

.data-table table tr td:last-child {
  border-bottom: 0; }

.data-table th,
.data-table td {
  max-width: 150px;
  margin: 0;
  padding: 0.5rem;
  border: 1px solid #f1eaf0;
  /* border-bottom: 1px solid black;
  border-right: 1px solid black; */ }

.data-table thead {
  background: #f6f9fc; }

.data-table-pagination {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0; }

.info-alert {
  cursor: pointer;
  color: #5799d1;
  border: 2px solid #5799d1;
  border-radius: 2rem;
  padding: 0;
  text-align: center;
  display: block;
  width: 3rem;
  margin: 0 auto; }

.info-text {
  color: #5799d1; }

.critical-alert {
  cursor: pointer;
  border: 2px solid #f3361c;
  color: #f3361c;
  border-radius: 2rem;
  padding: 0;
  text-align: center;
  display: block;
  width: 3rem;
  margin: 0 auto; }

.alert-header,
.site-col-header {
  text-align: center; }

.bmc-icon {
  width: 2rem;
  margin-right: 10px; }

/* Total BMC */
.under-chilled {
  color: #97a4ba; }

.empty {
  color: #000000; }

.chilled {
  color: #3794fc; }

/* .total-alert-table .export-icon {
  left: 6rem;
} */
/* Select Box */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2"); }

/* Check Box Style */
/* .role-table .export-icon {
  left: 2rem;
} */
/* 
.role-table .card-dropdown {
  right: 0px;
} */
/* Tale Pop Up Checkbox */
/* Info Table */

.inactive-button {
  opacity: 0.5;
  pointer-events: none; }

.title-on-border-box.bg-blue-l {
  background: #F6EFFF; }

div.MuiChip-root.gateway-label.MuiChip-deletable {
  display: inline-table !important;
  width: 950px !important;
  overflow-wrap: anywhere !important; }

span.MuiChip-label {
  white-space: break-spaces; }

.title-on-border-box {
  position: relative;
  border: 1px solid #c8d2d7;
  border-radius: .5em;
  padding: 1.5rem;
  overflow: visible !important;
  margin-top: 2em !important; }

.title-on-border-box span h3 {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -20px;
  width: inherit;
  background: white;
  padding: 0 .5em;
  border-radius: 1em; }

.config-dialogue {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px 1em; }

.config-dialogue :first-child {
  align-self: center; }

.success-msg .MuiPaper-root,
.error-msg .MuiPaper-root {
  padding: 0; }

.success-msg p,
.error-msg p {
  background: #148ae4;
  padding: 2rem;
  color: #fff;
  font-weight: 1000; }

.success-msg p i {
  display: block;
  font-size: 2rem; }

.success-msg .MuiSvgIcon-root,
.error-msg .MuiSvgIcon-root {
  display: block;
  margin: 0 auto;
  font-size: 2rem; }

.error-msg .MuiButton-containedPrimary {
  margin: 0 auto; }

.error-msg p {
  background: #e16733; }

.error-msg .info-msg {
  margin: 2rem 0; }

.error-msg .info-msg p {
  background: #fff;
  color: #000;
  padding: 0 2rem;
  font-weight: 200;
  font-size: 1rem;
  margin-bottom: 10px; }

@media screen and (max-width: 900px) {
  .title-on-border-box.fit-content-box {
    width: 100%; } }

/* Progress Bar */
.site .bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: cornflowerblue; }

.site .progress {
  margin: 20px auto;
  padding: 0;
  width: 90%;
  height: 10px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 40px; }

.site-id-label {
  padding-bottom: 2rem; }
  .site-id-label label {
    font-size: 12px; }

.add-icon {
  display: flex;
  color: #5799d1;
  background: none;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  margin-top: 0px;
  padding-left: 0px;
  transition: all .5s ease-in-out; }
  .add-icon .fa {
    font-size: 1.5rem; }
  .add-icon :hover {
    box-shadow: #444444;
    transform: translateX(2px); }

.delete-icon .fa {
  color: #ff4545;
  font-size: 1.5rem; }

.site .btn-secondary {
  box-shadow: 1px 2px 2px 1px #d5d5d5; }

.site .btn-primary {
  box-shadow: 1px 2px 2px 1px #efefef; }

.site .gateway-label {
  background: #ddedff;
  padding: 0.5rem 3rem;
  margin: 0rem 1rem;
  text-transform: uppercase; }

.site .gateway-label .MuiChip-deleteIcon {
  position: relative;
  left: 3rem; }

.site .deviceLabel {
  background: #fbf3d4; }

.pointer {
  border-left: 1px solid grey;
  height: 15px;
  border-bottom: 1px solid grey;
  width: 100px; }

@media (max-width: 576px) {
  .modal-dialog.modal-dialog-slideout {
    width: 80%; } }

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff; }

.modal.fade .modal-dialog.modal-dialog-slideout {
  transform: translate(100%, 0); }

.modal.fade.show .modal-dialog.modal-dialog-slideout {
  transform: translate(0, 0);
  flex-flow: column; }

.modal-dialog-slideout .modal-content {
  border: 0; }

.reports {
  display: inline-block;
  margin-top: 13px; }

.reports .MuiTypography-body1 {
  display: inline-block;
  vertical-align: top; }

.main-header .page-header {
  display: inline-block;
  width: 100%; }

.main-header .page-header div {
  display: inline-grid; }

.main-header .page-header nav {
  float: right;
  margin-top: 30px;
  display: inline-block; }

.report-category header {
  box-shadow: none;
  border-bottom: 1px solid #ddd; }

.reports .reportcard {
  padding: 34px;
  cursor: pointer; }

.reportcard {
  position: relative;
  text-align: center;
  width: 240px;
  height: 140px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  transition: .3s;
  border: 1px solid #ebebeb;
  margin: 5px;
  border-radius: 10px;
  background-color: aliceblue; }

.reportcard:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #e3f2fd; }

.card-body {
  flex: 1 1 auto;
  padding: .5%;
  font-size: 12px; }

.card-body p span {
  font-weight: bold;
  font-size: 16px;
  color: #2E384D;
  float: right;
  padding-right: 15px;
  padding-top: 15px; }

.card-body {
  vertical-align: middle;
  font-size: 12px; }

.card-title {
  margin-bottom: 0px;
  padding: 10px 0px; }

.test button {
  outline: none; }

.MuiTabs-indicator {
  background: #166aab !important; }

.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #585858;
    border-color: #585858 transparent #585858 transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #585858;
    border-color: #585858 transparent #585858 transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #585858;
    border-color: #585858 transparent #585858 transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
.textMiddle {
  color: #afb9ca; }

a:hover {
  text-decoration: none;
  color: #000; }

.table-bordered {
  border: 1px solid #dee2e6; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }

table td {
  height: 70px; }

table {
  border-collapse: collapse; }

*, ::after, ::before {
  box-sizing: border-box; }

.radio-group {
  position: relative; }

.radio {
  display: inline-flex;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #f1f3f4;
  border: 2px solid #f1f3f4;
  cursor: pointer;
  margin: 2px 0;
  margin-right: 8px;
  -webkit-box-align: center;
  justify-content: center; }

.radio1 {
  display: inline-flex;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #1a73e8;
  border: 2px solid #1a73e8;
  cursor: pointer;
  margin: 2px 0;
  margin-right: 8px;
  -webkit-box-align: center;
  justify-content: center; }

.radio.selected {
  border-color: grey; }

.spaceAtTopForDate {
  margin-top: 60px; }

.spaceAtTopForOccurence {
  margin-top: -70px;
  margin-left: -38px; }

@media screen and (max-width: 900px) {
  .title-on-border-box.fit-content-box {
    width: 100%; } }

/* Progress Bar */
.site .bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: cornflowerblue; }

.site .progress {
  margin: 20px auto;
  padding: 0;
  width: 90%;
  height: 10px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 40px; }

.site-id-label {
  padding-bottom: 2rem; }
  .site-id-label label {
    font-size: 12px; }

.add-icon {
  display: flex;
  color: #5799d1;
  background: none;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  margin-top: 0px;
  padding-left: 0px;
  transition: all .5s ease-in-out; }
  .add-icon .fa {
    font-size: 1.5rem; }
  .add-icon :hover {
    box-shadow: #444444;
    transform: translateX(2px); }

.delete-icon .fa {
  color: #ff4545;
  font-size: 1.5rem; }

.site .btn-secondary {
  box-shadow: 1px 2px 2px 1px #d5d5d5; }

.site .btn-primary {
  box-shadow: 1px 2px 2px 1px #efefef; }

.site .gateway-label {
  background: #ddedff;
  padding: 0.5rem 3rem;
  margin: 0rem 1rem;
  text-transform: uppercase; }

.site .gateway-label .MuiChip-deleteIcon {
  position: relative;
  left: 3rem; }

.site .deviceLabel {
  background: #fbf3d4; }

.pointer {
  border-left: 1px solid grey;
  height: 15px;
  border-bottom: 1px solid grey;
  width: 100px; }


@media screen and (max-width: 900px) {
  .title-on-border-box.fit-content-box {
    width: 100%; } }

/* Progress Bar */
.site .bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: cornflowerblue; }

.site .progress {
  margin: 20px auto;
  padding: 0;
  width: 90%;
  height: 10px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 40px; }

.site-id-label {
  padding-bottom: 2rem; }
  .site-id-label label {
    font-size: 12px; }

.add-icon {
  display: flex;
  color: #5799d1;
  background: none;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  margin-top: 0px;
  padding-left: 0px;
  transition: all .5s ease-in-out; }
  .add-icon .fa {
    font-size: 1.5rem; }
  .add-icon :hover {
    box-shadow: #444444;
    transform: translateX(2px); }

.delete-icon .fa {
  color: #ff4545;
  font-size: 1.5rem; }

.site .btn-secondary {
  box-shadow: 1px 2px 2px 1px #d5d5d5; }

.site .btn-primary {
  box-shadow: 1px 2px 2px 1px #efefef; }

.site .gateway-label {
  background: #ddedff;
  padding: 0.5rem 3rem;
  margin: 0rem 1rem;
  text-transform: uppercase; }

.site .gateway-label .MuiChip-deleteIcon {
  position: relative;
  left: 3rem; }

.site .deviceLabel {
  background: #fbf3d4; }

.pointer {
  border-left: 1px solid grey;
  height: 15px;
  border-bottom: 1px solid grey;
  width: 100px; }

.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #585858;
    border-color: #585858 transparent #585858 transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

  .singleSelect{
    height: 10px !important;
  }

