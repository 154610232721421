@media (max-width: 576px) {
  .modal-dialog.modal-dialog-slideout {
    width: 80%
  }
}

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  flex-flow: column;
}

.modal-dialog-slideout .modal-content {
  border: 0;
}

.reports {
  display: inline-block;
  margin-top: 13px;
}

.reports .MuiTypography-body1 {
  display: inline-block;
  vertical-align: top;
}

.main-header .page-header {
  display: inline-block;
  width: 100%;
}

.main-header .page-header div {
  display: inline-grid;
}

.main-header .page-header nav {
  float: right;
  margin-top: 30px;
  display: inline-block;
}

.report-category header {
  box-shadow: none;
  border-bottom: 1px solid #ddd;
}

.reports .reportcard {
  padding: 34px;
  cursor: pointer;
}

.reportcard {
  position: relative;
  text-align: center;
  width: 240px;
  height: 140px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1);
  -webkit-transition: .3s;
  transition: .3s;
  border: 1px solid #ebebeb;
  margin: 5px;
  border-radius: 10px;
  background-color: aliceblue;
}

.reportcard:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #e3f2fd;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: .5%;
  font-size: 12px;
}

.card-body p span {
  font-weight: bold;
  font-size: 16px;
  color:
    /*#ffffff;*/
    #2E384D;
  float: right;
  padding-right: 15px;
  padding-top: 15px;
}

.card-body {
  vertical-align: middle;
  font-size: 12px;
}

.card-title {
  margin-bottom: 0px;
  padding: 10px 0px;
}

.test {
  button {
    outline: none;
  }
}

.MuiTabs-indicator {
  background: #166aab !important;
}
