body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  background-color: #f6f9fc !important;
  overflow: hidden;
}
button:focus {
  outline: none;
}
img:focus {
  outline: none;
}
p {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px !important;
}
span {
  font-size: 12px;
}
// .MuiOutlinedInput-adornedEnd {
//   padding: "0em";
// }
/*.MuiIconButton-root:hover {
  background-color: rgba(63, 81, 181, 0.08) !important;
}*/
.blue-num {
  margin-left: 5px;
  font-size: 14px;
  color: #307ec2;
  font-weight: 600;
}
.fa {
  color: #5799d1;
  font-size: 16px;
  text-align: center;
}
.fa-globe {
  color: rgba(243, 241, 252, 0.55);
}
a {
  color: #5799d1;
  cursor: pointer;
}
h2 {
  font-size: 16px;
  font-weight: 501;
}
h3 {
  font-weight: 600;
  font-size: 12px;
  margin: 1em 0em;
}
tbody {
  //to target the one column in table which has hyperlink not tagged with "a",in ALL BMC table
  tr {
    td {
      text-align: center;
    }
    td:first-child {
      span {
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
    }
  }
}
sup {
  top: -0.1em;
  font-size: 14px;
}
.track-vertical {
  //scrollbar track color
  background-color: #c4cfdd;
  height: 100%;
  top: 0;
  right: 0;
}
.track-vertical-index {
  //scrollbar for the entire page
  margin-top: 5em;
  height: calc(100vh - 5em);
}
.thumb-vertical {
  //scrollbar thumb color
  background-color: #478fcc;
  border-radius: 2em;
}
/*.MuiTypography-body1 {
  font-size: 12px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // "Critical Alerts in WebViewChart.js is targetted from here
}*/

.card-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
}

// #main {
//   margin-left: 55px;
//   overflow-x: hidden;
// }
// @media only screen and (max-width: 1400px) {
//   #main {
//     margin-left: 0;
//   }
// }
/* Site Wrapper */
// .language-md {
//   position: relative;
//   padding: 0rem 0rem 0rem 1.2rem;
//   top: 2px;
// }
// .language-md .MuiSelect-icon {
//   color: rgba(243, 241, 252, 0.55);
// }
// .language-md .MuiInputBase-input {
//   color: rgba(243, 241, 252, 0.55) !important;
// }
// .language-md .MuiInput-underline:hover:not(.Mui-disabled):before {
//   border: none;
// }
// .language-md .MuiInput-underline:before {
//   left: -10px;
//   right: 0;
//   top: 7px;
//   content: "\00a0";
//   position: absolute;
//   pointer-events: none;
//   border-radius: 5px;
//   border: none;
// }
/*.MuiOutlinedInput-root,
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover,
input {
  font-size: 12px !important;
  //all input fields are targetted from here
}*/
.site-add {
  border: 1px solid #fff;
  padding: 15px 0 15px 15px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.site-profile {
  border: 2px solid #fff;
  margin: 5px !important;
  border-radius: 3px;
}
.device-profile {
  border: 2px solid #fff;
  border-radius: 3px;
  margin-left: 2px !important ;
  margin-bottom: 15px !important;
  margin-top: 2px !important;
  margin-right: -2px !important;
}
.common-device {
  border: 2px solid #fff;
  border-radius: 3px;
  margin: 1px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.logout-btn {
  position: absolute;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.data-table tr th, .data-table tr td {
  text-overflow: initial !important;
}

.mandatoryField::after{
  content: "*";
  color: red;
  // font-size: 20px;
  font-weight: bold;
}

.MuiFormLabel-asterisk{
  color: red;
}


