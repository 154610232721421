.title-on-border-box {
  position: relative;
  border: 1px solid #c8d2d7;
  border-radius: .5em;
  padding: 1.5rem;
  overflow: visible !important;
  margin-top:2em !important;
}

.title-on-border-box span h3 {
  font-size: 12px;
  font-weight:600;
  position: absolute;
  top: -20px;
  width:inherit;
  background: white;
  padding: 0 .5em;
  border-radius: 1em;
}

.config-dialogue {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px 1em;
}

.config-dialogue :first-child {
    align-self: center;
}



// ".title-on-border-box" targets the ChillingUnitProfile heading,to keep it on top of the box

// .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
//   background-color: #3f51b5;
// }

// .multi-select .MuiButtonBase-root {
//   border: 1px solid rgba(0, 0, 0, 0.23);
//   background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
//   background-repeat: no-repeat;
//   background-position-x: 100%;
//   background-position-y: 5px;
// }
// .multi-select .MuiButton-label {
//   justify-content: left;
//   padding-left: 0.5rem;
// }
// .multi-select .MuiButton-root:hover {
//   background-color: #fff;
// }
