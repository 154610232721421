@media screen and (max-width: 600px) {
  /* Login Page */
  .auth-page > .container {
    margin-top: 1rem;
  }
  .container {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  /* Table */

  .page-btm-data,
  .page-btn {
    text-align: center !important;
  }
}
/* Mobile Resolution Landscape */
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .auth-page {
    height: 160vh !important;
  }
}
