
@media screen and (max-width: 900px) {
    .title-on-border-box.fit-content-box {
      width: 100%;
    }
  }
  
  /* Progress Bar */
  .site {
    .bar {
      position: relative;
      float: left;
      min-width: 1%;
      height: 100%;
      background: cornflowerblue;
    }
  
    .progress {
      margin: 20px auto;
      padding: 0;
      width: 90%;
      height: 10px;
      overflow: hidden;
      background: #e5e5e5;
      border-radius: 40px;
    }
  }
  
  
  .site-id-label {
    label{
      font-size: 12px;
    }
    padding-bottom: 2rem;
  }
  
  .add-icon {
    display: flex;
    color: #5799d1;
    background: none;
    border: none;
    outline: none;
    .fa {
      font-size: 1.5rem;
    }
    position: relative;
    cursor: pointer;
    margin-top:0px; 
    padding-left: 0px;
    transition: all .5s ease-in-out; 
    & :hover{
      box-shadow: #444444;
      transform: translateX(2px)
    }
     // right: 1rem;
    //top: 8px;
  }
  .delete-icon {
    .fa {
      color: #ff4545;
      font-size: 1.5rem;
    } 
  }
  
  .site .btn-secondary {
    box-shadow: 1px 2px 2px 1px #d5d5d5;
  }
  .site .btn-primary {
    box-shadow: 1px 2px 2px 1px #efefef;
  }
  .site .gateway-label {
    background: #ddedff;
    padding: 0.5rem 3rem;
    margin: 0rem 1rem;
    text-transform: uppercase;
  }
  .site .gateway-label .MuiChip-deleteIcon {
    position: relative;
    left: 3rem;
  }
  .site .deviceLabel {
    background: #fbf3d4;
  }
  
  .pointer {
    border-left: 1px solid grey;
    height: 15px;
    border-bottom: 1px solid grey;
    width: 100px;
  }