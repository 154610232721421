.success-msg .MuiPaper-root,
.error-msg .MuiPaper-root {
  padding: 0;
}
.success-msg p,
.error-msg p {
  background: #148ae4;
  padding: 2rem;
  color: #fff;
  font-weight: 1000;
}
// .success-msg .btn-cstm-blue {
//   background-color: #166aab;
// }
// .success-msg .btn-cstm-ltblue {
//   background-color: #478ecc;
// }

.success-msg p i {
  display: block;
  font-size: 2rem;
}

.success-msg .MuiSvgIcon-root,
.error-msg .MuiSvgIcon-root {
  display: block;
  margin: 0 auto;
  font-size: 2rem;
}

// .success-msg .MuiButton-containedPrimary,
// .success-msg .MuiButton-outlined,
// .error-msg .MuiButton-containedPrimary {
//   display: block;
//   margin: 1rem auto;
//   width: 58%;
//   text-transform: capitalize;
// }
.error-msg .MuiButton-containedPrimary {
  margin: 0 auto;
}

.error-msg p {
  background: #e16733;
}
.error-msg .info-msg {
  margin: 2rem 0;
}

.error-msg .info-msg p {
  background: #fff;
  color: #000;
  padding: 0 2rem;
  font-weight: 200;
  font-size: 1rem;
  margin-bottom: 10px;
}
// .minh-100 .MuiButton-label {
//   text-transform: capitalize;
// }
