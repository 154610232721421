.table-bordered {
    border: 1px solid #dee2e6;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    
}
table td {
    height: 70px ;
}
table {
    border-collapse: collapse;
}

*, ::after, ::before {
    box-sizing: border-box;
}
